<template>
    <div class="jobs dirr">
      <div v-if="!active">
        <p
          class="tl yellow--text text--darken-4 pa-8 ma-8"
          style="text-align:center;"
        >
          عذرًا، قد انتهت فترة التقديم
        </p>
        <v-btn
          color="rgba(177, 189, 82, 0.84)"
          to="/"
        >
          الرجوع للرئيسية
        </v-btn>
      </div>
  
      <v-layout row v-else>
        <v-layout row>
          <p class="tl" style="text-align:center;">
            يعلن قسم الإعلام في العتبة العباسية المقدّسة عن فتح باب التقديم لشواغر وظيفية في التخصصات المهنيّة والإعلاميّة.
          </p>
        </v-layout>
  
        <v-layout row>
          <v-form
            class="form"
            ref="form"
            v-model="valid"
            style="padding: 30px !important;margin: 10px !important;"
          >
            <v-layout row class="fields">
              <v-layout row>
                <v-flex xs12>
                  <div class="required ta" style="margin: 0px !important;">
                    <p class="tl big">التخصصات المطلوبة:</p>
                    <ol  class="mr-6 jobs-list" >
                      <li style="margin-right: 30px;" class="job-item tl"  v-for="r in jobsRes" :key="r">{{ r.title }}</li>
                    </ol>
                    <br>
                  </div>
                </v-flex>
              </v-layout>
        
     
              <v-flex xs12 sm6 md6 lg6 pt-4>
                <p class="tl label">الاسم الرباعي واللقب.</p>
                <v-text-field
                  @keyup.enter.native="send()"
                  v-model="info.name"
                  background-color="#FFFFFF"
                  :rules="[rules.required]"
                  placeholder="اكتب الاسم هنا"
                  outlined
                ></v-text-field>
              </v-flex>
  
              <v-flex xs12 sm6 md6 lg6>
                <p class="tl label">رقم الموبايل</p>
  
                <v-text-field
                  required
                  @keyup.enter.native="send()"
                  v-model="info.phone"
                  @keypress="isNumber($event)"
                  :rules="[rules.required]"
                  style="direction: ltr"
                  background-color="#FFFFFF"
                  placeholder="07XX XXX XXXX"
                  v-mask="mask"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-spacer></v-spacer>
              
              <v-flex xs12 sm6 md6 lg6>
                <p class="tl label">المحافظة</p>
                <v-select
                  required
                  :items="govs"
                  item-text="title"
                  v-model="info.gov_code"
                  item-value="id"
                  background-color="#FFFFFF"
                  :rules="[rules.required]"
                  label="اضغط لاختيار المحافظة"
                  outlined
                ></v-select>
              </v-flex>
    
              <v-flex xs12 sm6 md6 lg6>
                <p class="tl label"> الاختصاص </p>
                <v-select required :items="jobsRes" item-text="title" v-model="info.required_specialties_id" item-value="id"  background-color="#FFFFFF" :rules="[rules.required]" label="اضغط لعرض العنوان" outlined></v-select>
              </v-flex>
              <v-spacer></v-spacer>

              <v-layout row>
                <div style="width: 100%; text-align: right">
                  <p class="tl bottom mb-6" style="width: 100%;">
                    <p v-if="info.required_specialties_id" class="tl big"> الشروط: </p> 
                    <template v-for="r in jobsRes">
                      <div v-if="r.id == info.required_specialties_id" class="rules">{{ r.text }}</div>
                    </template>
                    
                  </p>
                </div>
              </v-layout>


              <v-flex class="fileUpload" xs12 sm6 md6 lg6>
                <p class="tl label mx-6">ارفق السيرة الذاتية (نوع الملف PDF)</p>
                <v-file-input accept=".pdf" type="file" v-model="filename" clearable="true" @change="uploadFile($event)" ref="fileupload" color="#FFFFFF" label="اضغط هنا لرفع السيرة الذاتية" placeholder="اضغط هنا لرفع السيرة الذاتية" prepend-icon="mdi-paperclip" outlined ></v-file-input>
            </v-flex>
            </v-layout>


            

<!--   
            <v-layout row>
              <div>
                <p class="tl bottom mb-6" style="width: 100%;">
                  <p class="tl big">المستمسكات المطلوبة:ـ </p>
                  <p class="tl">1- نسخة مصورة من البطاقة الوطنية</p>
                  <p class="tl">2- نسخة مصورة من بطاقة السكن</p>
                </p>
              </div>
            </v-layout> -->
            <v-layout row>
              <p
                style="width: 100%;"
                :style="$vuetify.theme.dark ? 'border-bottom: solid thin #FFF' : 'border-bottom: solid thin #ccc'"
              ></p>
            </v-layout>
  
            <v-layout row>
  
              <div class="fileUpload">
                <p class="tl label">ارفق المستمسكات (البطاقة الوطنية + بطاقة السكن)</p>
                <vue-dropzone
                  ref="dropzone"
                  :useCustomSlot="true"
                  id="dropzone"
                  :options="dropzoneOptions"
                  @vdropzone-success="success"
                  @vdropzone-file-added="fileAdded"
                  @vdropzone-error="collectErrors"
                >
                  <div class="dropzone-container">اضغط هنا لرفع المستمسكات</div>
                </vue-dropzone>
              </div>
            </v-layout>
  
            <v-layout row>
              <p class="tl bottom pt-6" style="text-align:center;width: 100%;">
                يكون التقديم عبر هذه الاستمارة حصراً لغاية ({{ end_date }}م) <br>
                وعدم مراجعة ادارة العتبة العباسية المقدسة
              </p>
            </v-layout>
  
            <v-layout row>
              <v-btn
                style="align-self: center;"
                min-width="250"
                height="56"
                class="ma-2 tl mx-auto"
                :loading="loading"
                color="#303030"
                @click="upload()"
              >
                <p class="alistSub tl">ارسل الأستمارة</p>
              </v-btn>
            </v-layout>
          </v-form>
        </v-layout>
      </v-layout>
    </div>
  </template>
    
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Swal from "sweetalert2";
import { mask } from "vue-the-mask";
export default {
    metaInfo() {
        return {
            title:'استمارة التقديم على الوظيفة',
            titleTemplate: 'استمارة التقديم على الوظيفة'
        };
    },

    data() {
        return {
            active: true,
            start_date: '',
            end_date: '',
            loader: null,
            loading: false,
            valid: false,
            info:{ name:'', gov_code: '', phone:'', images:this.image, required_specialties_id:'', cv:'' },

            govs: [{id:1, title: "كربلاء"}],

            jobsRes: [],
            image: [],
            cv: null,
            certificate: null,
            cv_uploaded: false,
            cert_uploaded: false,
            start_post: false,
            error: false,
            catch_error: null,
            mask: "07XX XXX XXXX",
            rules: {
                required: value => !!value || this.$t("required_field"),
                min: (v) => v.length > 10 || 'رقم الهاتف غير صالح',
                minPhon: (v) => v.length == 11 || "رقم الهاتف يجب ان يتكون من 11 رقم",
                size : v => !!v || this.$t("required_field"),
                size : v => (v && v.size > 0) || this.$t("required_field"),
            },
            numberRule: v  => {
                if (!v.trim()) return true;
                if (!isNaN(parseFloat(v)) && v >= 18 && v <= 30) return true;
                return 'العمر يجب ان لايتجاوز 30';
            },
            dropzoneOptions: {
                url: 'https://alkafeel.net/alkafeel_back_test/api/v1/jops/uploude_image',
                thumbnailWidth: 150,
                maxFilesize: 100,
                acceptedFiles: ".jpeg,.jpg,.png,.gif,.heic,.heif",
                parallelUploads: 5,
                maxNumberOfFiles:5,
                timeout: 600000,
                // uploadMultiple: true,
                maxFiles: 8,
                renameFile: function (file) {
                    return new Date().getTime() + '_' + file.name
                },
                
                sending: function(file, xhr, formData) {
                    formData.append("_token", "{{{ csrf_token() }}}");
                },
            }
        }
    },
    watch: {
    loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 2000)
        this.loader = null
    },

    start_post(n, o) {
        
        if (n) {
            if (!this.error) {
                if (this.info.images === this.image) {
                    this.post_data();
                }
            } else {
                Swal.fire({
                    title: this.error,
                    text: "",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 5000,
                    confirmButtonColor: '#B1BD52',
                });
                this.error = false;
                this._data.start_post = false
            }
        }
    }
    },
    components: { vueDropzone: vue2Dropzone },
    directives: { mask },
    mounted() {  
        document.title = 'استمارة التقديم على الوظيفة';  
    }, 
    created () {
        this.get_active();
        this.get_govs();
        this.getjobs();
    },
    methods:{
        get_govs () {
            this.$axios.get("jops/getGovs", {})
            .then(res => {
                this.govs = res.data;
            })
        },
        stp() {
            this.info.images=this.image;

            if(this.image.length==0){
                this.error = 'صور المستمسكات مطلوبة';
            } 
            this.start_post = true
        },
        get_active () {
            this.$axios.get("jobs2/getTime", {})
            .then(res => {
                // console.log(res.data.success)
                this.active = res.data.success;
                this.start_date = res.data.start;
                this.end_date = res.data.end;
            })
        },

        upload() {
            // console.log('upload started')
            this.start_post = false;
            this.loading=true;
            if (this.$refs.form.validate()) {
                // this.loader = 'loading';
                this.info.images=this.image;

                if(this.image.length==0){
                    this.error = 'صور المستمسكات مطلوبة';
                } 

                const headers = { 'Content-Type': 'multipart/form-data' };
                if (this.cv) {
                    if (!this.cv_uploaded) {
                        const formData = new FormData();
                        formData.append('cv', this.cv);

                        this.$axios.post('jops/uploude_cv', formData, { headers })
                        .then((x) => {
                            if (x.status == 200) {
                                this.info.cv = x.data.data;
                                this.cv_uploaded = true;
                                this.start_post = true;
                            } else {
                                this.error = 'تأكد من ملف السيرة الذاتية والمحاولة مجددا';
                                this.start_post = true;
                            }
                        });
                    } else {
                        this.start_post = true;
                    }
                } else {
                    this.error = 'تأكد من ملف السيرة الذاتية والمحاولة مجددا';
                    this.start_post = true;
                }
                  
            } else{
                window.scrollTo(0,0)
            }
        },
        post_data () {
          this.loading=true;
            this.loader = 'loading';
            this.$axios.post('jobs2/send', this.info)
            .then(res => {
                if (res.status == 200) {
                  this.loading=false;
                    Swal.fire({
                        title: 'تم رفع طلب التقديم على الوظيفة بنجاح',
                        text: "",
                        icon: "success",
                        timer: 3000,
                        showConfirmButton: false,
                    }).then(function() {window.location = "/"; return false});
                } else {
                  this.loading=false;
                    Swal.fire({
                        title: 'حدث خطأ اثناء التقديم ، يرجى المحاولة مجددا',
                        text: "",
                        icon: "error",
                        timer: 5000,
                        confirmButtonColor: 'red',
                    })
                    .then(function() {window.location = "/jobs"; return false});
                }
            }).catch(error => {
              this.loading=false;
                if (error.response.status == 403) {
                    this.catch_error = error.response.data
                } else {
                    this.catch_error = 'حدث خطأ اثناء التقديم ، يرجى المحاولة مجددا'
                }
                Swal.fire({
                    title: this.catch_error,
                    text: "",
                    icon: "error",
                    timer: 5000,
                    confirmButtonColor: 'red',
                })
                // .then(function() {window.location = "/jobs"; return false});
            });
        },
        
        uploadFile(file) {
            this.cv = file;
            if (file && file.size > 1024 * 1024 * 20) {
                this.$refs.fileupload.reset();
                return Swal.fire({
                    title: 'حجم السيرة الذاتية يجب ان يكون اقل من 5 ميجابايت',
                    text: "",
                    icon: "error",
                    //timer: 5000,
                    confirmButtonColor: 'red',
                });
            }
        },
        getjobs() {
            this.$axios.get("jops/media_spec", {})
            .then(res => {
                this.jobsRes = res.data;
            })
            .catch(() => {});
        },
        
        
        
        
        
        
                

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {evt.preventDefault();;}
            else {return true;}
        },
        success(file, response) {
            // this.image.push(file.upload.filename);
            this.image.push(response.data);
            // console.log(this.image);
        }, 
        collectErrors(){
        },
        fileAdded(file) {
            // console.log("File Dropped => ", file.upload.filename);
            // this.image.push(file.upload.filename);
            // console.log(this.image);
        }
    },
}
</script>
<style scoped>
.rules {width:100%;padding:10px; border-radius: 3px; white-space: pre-wrap}
</style> 
<style >
    .dropzone-container {
        display: flex;
        flex-direction: column;
        border: 1px dashed #ccc;
        border-radius: 15px;
    }
    .dropzone .dz-preview.dz-image-preview{
        z-index: 1 !important;
    }

    .jobs-list {
  display: flex;
  flex-wrap: wrap; /* Ensures items wrap on smaller screens */
}

.job-item {
  margin-right: 30px;
}

/* Media query for phones (max-width: 768px is typical for tablets and smaller devices) */
@media (max-width: 768px) {
  .jobs-list {
    display: inline-grid;
  }

  .job-item {
    display: inline-grid;

    margin-right:0px !important; /* Adjust margin for better spacing on small screens */
  }
}

</style>