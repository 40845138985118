<template>
    <div class="container" style="max-width: 1261px !important;">
        <div class="panl-title">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <router-link :to="'/lib?lang='+$i18n.locale"
                    style="text-decoration: none !important;">
                    <div class="tl panl-title-text d-inline"> {{$t('pub_latest')}} </div>
                </router-link>
            </div>
            
      <div class="row mt-1">
        <div class=" col-6 col-md-3 col-lg-2" v-for="item in items" :key="item.id">
            
                <div class="card main-pub text-center">
                    <router-link :to="'/lib/'+item.cat+'?lang='+$i18n.locale" ><img :src="'https://alkafeel.net/publications/img/200/'+item.img" class="card-img-top" alt="عنوان الكتاب"></router-link>
                    <div class="card-body">
                    <h5 class="card-title">{{ item.title }}</h5>
                    <p v-if="item.ver" class="card-text">{{$t("pub_ver")}}: {{ item.ver }}</p>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          { id: 1, title: 'مواقف الأطفال', issue: 68, image: 'assets/img/pub/pub-4.png' },
          { id: 2, title: 'نشرة الخميس', issue: 32, image: 'assets/img/pub/pub-1.png' },
          { id: 3, title: 'بنات الهدى', issue: 3, image: 'assets/img/pub/pub-2.png' },
          { id: 4, title: 'المشكاة', issue: 229, image: 'assets/img/pub/pub-3.png' },
          { id: 5, title: 'صدى الروضتين', issue: 441, image: 'assets/img/pub/pub-5.png' },
          { id: 6, title: 'نشرة الخميس', issue: 32, image: 'assets/img/pub/pub-1.png' },

        ]
      };
    },
    mounted() {
      this.ini();
    },
    methods: {
        ini () {
            this.$axios.get('pub/latest/6')
            .then (d => {
                this.loading = false;
                this.items = d.data;
            })
        },
    }
  };
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 20px;
  }
  .card img {
    height: 200px;
    object-fit: cover;
  }
  .card-title {
    font-size: 1.1rem;
  }
  .card-text {
    font-size: 0.9rem;
  }

  .main-pub {
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 12px 0 0 0;
    transition: transform 0.3s ease-in-out; 

  }

  .main-pub:hover {
     transform: scale(1.1);
  }
  </style>
  

  <!--
  
  <template>
    <div v-if="dat.length">



         <a class="height" href="https://alkafeel.net/uploads/AlkafeelNaskh_11_1.zip">
            <v-img contain :min-height="$vuetify.breakpoint.smAndDown ? 300 : 180" :src="$vuetify.breakpoint.smAndDown ? '/assets/img/font_ad_mob.jpg' : '/assets/img/font_ad_web.jpg'" alt=""></v-img>
        </a> 

        <div class="fill-height  hidden-md-and-down" :class="$vuetify.breakpoint.xl ? 'mask_grad' : 'mask_grad_lg'">
          <v-container class="pub mx-auto">
              <v-layout row mt-4 style="position: relative;" >


                  <v-sheet class="pub_title py-8 ps-2 pe-2" min-height="129" max-width="400">
                      <router-link :to="'/lib?lang='+$i18n.locale" style="text-decoration:none; color: #000">
                          <span style="padding:0 10px" :style="$vuetify.theme.dark ? 'content: url(/assets/img/z-mohammed_dark.svg)' : 'content: url(/assets/img/z-mohammed.svg)'"></span> 
                          <span class="tl main_title">{{$t('pub')}}</span>
                          <div class="tl sub_title">{{$t('pub_latest')}} {{$t('to_al_Kafeel_net')}}</div>
                      </router-link>
                  </v-sheet>
                  
                  <v-sheet class="pub_content px-8 pt-6 mt-n3" height="380" width="870" :style="$vuetify.rtl ? 'right:auto;left:0;' : 'left:auto;right:0;'">
                 <v-layout style="justify-content: end">
                          <template v-if="dat">
                              <template  v-for="item in dat">
                                  <v-flex xl3 lg3 mx-3 :key="item">
                                      <router-link :to="'/library/'+item.cat+'?lang='+$i18n.locale" style="text-decoration:none; color: #000">
                                          <v-img rounded class="mx-auto" height="250" :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/200/'+item.img" ></v-img>
                                          <div class="t1 pub_stitle" style="">{{item.title}}</div>
                                      </router-link>
                                  </v-flex>
                              </template>
                          </template>
                      </v-layout> 

                      <swiper :options="swiperOptionsD" ref="mySwiper" class="swiper dirr">
                          <template v-for="item in dat">
                              <swiper-slide :key="item" >
                                  <router-link :to="'/lib/'+item.cat+'?lang='+$i18n.locale" style="text-decoration:none; color: #000">
                                      <v-img rounded class="mx-auto" height="283" :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/200/'+item.img" ></v-img>
                                      <div class="t1 pub_stitle" style="">{{item.title}}</div>
                                  </router-link>
                              </swiper-slide>
                          </template>
                          <swiper-slide></swiper-slide>
                          <swiper-slide></swiper-slide>
                          
                          <div class="swiper-pagination" slot="pagination"></div>
                      </swiper>
                  </v-sheet>


              </v-layout>
          </v-container>
      </div>

      <div class="fill-height mask_grad_m messiri_fonted hidden-lg-and-up">
          <v-container class="pub_m mx-auto dirr" style="position: relative">
              


                  <v-sheet class="pub_title_m mt-2 py-8 ps-6 pe-6 mx-auto" >
                      <router-link :to="'/lib?lang='+$i18n.locale" style="text-decoration:none; color: #000">
                          <span style="padding:0 10px" :style="$vuetify.theme.dark ? 'content: url(/assets/img/z-mohammed_dark.svg)' : 'content: url(/assets/img/z-mohammed.svg)'"></span> 
                          <span class="tl main_title_m">{{$t('pub')}}</span>
                          <div class="tl sub_title_m">{{$t('pub_latest')}} {{$t('to_al_Kafeel_net')}}</div>
                      </router-link>
                  </v-sheet>


                   <v-sheet class="pub_content_m  mx-auto" >
                   
                      <swiper :options="swiperOptions" ref="mySwiper" class="swiper pt-16 pb-10">
                          <template v-for="item in dat">
                              <swiper-slide :key="item" >
                                  <router-link :to="'/lib/'+item.cat+'?lang='+$i18n.locale" style="text-decoration:none; color: #000">
                                      <v-img rounded class="mx-auto" height="347" width="255" :lazy-src="'https://alkafeel.net/publications/img/90/'+item.img" :src="'https://alkafeel.net/publications/img/400/'+item.img" ></v-img>
                                      <div class="t1 pub_stitle" style="">{{item.title}}</div>
                                  </router-link>
                              </swiper-slide>
                          </template>
                          <swiper-slide>
                              <v-card flat>
                                  <router-link :to="'/lib?lang='+$i18n.locale" style="text-decoration:none; color: #000; margin-top: 0;">
                                      <v-list-item-avatar tile height="316" width="220" class="white--text" color="grey">{{$t('more')}}</v-list-item-avatar>
                                  </router-link>
                              </v-card>
                          </swiper-slide>
                          <swiper-slide></swiper-slide>
                          <swiper-slide></swiper-slide>
                      </swiper>
                      
                   </v-sheet>



          </v-container>
      </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
// import 'swiper/css/swiper.css';
// import axios from "axios";
export default {
  data() {
      return {
          dat: [],
          loading: true,
          swiperOptions: {
              slidesPerView: "3",
              freeMode: true,
              loop: false,
              width:900,
              Observer: true,
              ObserveParents: true
          },
          swiperOptionsD: {
              autoplay: true,
              // loop:true,
              slidesPerView: "3",
              spaceBetween:25,
              freeMode: true,
              width:700,
              Observer: true,
              ObserveParents: true,
              pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
              },
          },
      }
  },

  mounted() {
      this.ini();
  },
  components: {
      // Swiper, SwiperSlide
  },
  directives: {
      // swiper: directive
  },
  computed: {
      // swiper() {return this.$refs.mySwiper.$swiper},
  },
  methods: {
      ini () {
          this.$axios.get('pub/latest/6')
          .then (d => {
              this.loading = false;
              this.dat = d.data;
              // console.log(this.dat)
          })
      },
  }
}
</script>

<style scoped>
  .mask_grad {
      background:url('/assets/img/pub/z-mohammed.png'),linear-gradient(to left, rgba(177, 189, 82, 0.3) 0%, rgba(177, 189, 82, 0) 100%);
      background-repeat: no-repeat;
      background-position: 83% center;
  }
  .mask_grad_lg {
      background:url('/assets/img/pub/z-mohammed.png'),linear-gradient(to left, rgba(177, 189, 82, 0.3) 0%, rgba(177, 189, 82, 0) 100%);
      background-repeat: no-repeat;
      background-position: 100% center;
  }
  .mask_grad_m {
      background:linear-gradient(to bottom, rgba(177, 189, 82, 0.3) 0%, rgba(177, 189, 82, 0) 100%);
      background-repeat: no-repeat;
  }
  .pub {
      width: 100%;
      padding-top: 30px;
      max-width: 1100px;
      min-height: 460px;
  }
  .pub_m {
      width: 100%;
      padding-top: 30px;
      min-height: 640px;
      padding: 15px;
  }
  .pub_title {
      text-align: center;
      position: absolute;
      z-index: 9;
      border-radius: 4px;
      /* border: solid thin #ccc; */
      /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.17); */
      top: 116px
  }
  .pub_content {
      position: absolute;
      text-align: end;
      border-radius: 8px;
      /* box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.13); */
  }
  .pub_title_m {
      text-align: center;
      position: absolute;
      z-index: 9;
      border-radius: 25px;
      background-color: #FFF;
      /* box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.17); */
      border: solid thin #ccc;
      right: 55px;
      left: 55px;
      top: 10px
  }
  .pub_content_m {
      position: absolute;
      border-radius: 25px;
      background-color: #FFF;
      /* box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.13); */
      right: 15px;
      left: 15px;
      top: 110px
  }
  .main_title {
      color: #000;
      font-size: 24px;
      font-family: 'cairo_bold';
      vertical-align: super;
  }
  .sub_title {
      font-weight: 700;
      font-size: 16px;
      word-break:break-all
  }

  .main_title_m {
      color: #000;
      font-size: 20px;
      font-family: 'cairo_bold';
      vertical-align: super;
  }
  .sub_title_m {
      font-weight: 700;
      font-size: 14px;
      word-break:break-all;
  }
  .pub_stitle {
      padding-top: 10px;
  padding-bottom: 5px;
      font-size: 16px; 
      font-weight: 700;
      text-align: center;
      max-height: 40px;
      word-break: break-all;
      overflow: hidden;
  }
</style>
  -->